import { AdsSlot, VideoAdsSlot } from 'components/ads';
import AdsenseSLots from 'components/ads/AdsenseSLots';
import JubnaAds from 'components/ads/JubnaAds';

import { RelatedSlider } from 'components/shared';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { BlocksDirectory } from './blocks';
import { useMenu, useSiteConfig } from 'hooks';
import StyledList from './acf/StyledList';
import { htmlParser } from 'utls';
import ArticleSummary from './acf/ArticleSummary';
import MenuSlider from 'components/shared/MenuSlider';
import BioPersonOfDay from './acf/BioPersonOfDay';
import AiCard from './acf/AiCard';
const DeviceSpecification = dynamic(
  () => import('components/shared/DeviceSpecification'),
  {
    ssr: false
    // loading: SourcesSkeleton,
  }
);

export const GutenbergRenderer = ({
  blocks,
  postId,
  type,
  acf,
  related_items,
  article_summary,
  object_type,
  bio_person_of_day
}) => {
  const FallbackComponent = BlocksDirectory?.['fallback'];

  const { adsSlots } = useSiteConfig();
  // const { header, sidebar, in_article_1, in_article_2 } =
  //   adsSlots && adsSlots[0];

  console.log(article_summary, 'article_summaryarticle_summary');

  const MainMenu = useMenu('main-menu');
  console.log(MainMenu, 'MainMenu');
  console.log(bio_person_of_day, 'bio_person_of_day');

  return (
    <>
      {adsSlots && adsSlots[0]?.header?.client_id && (
        <AdsenseSLots data={adsSlots[0]?.header} />
      )}

      {blocks
        ?.filter(({ blockName }) => Boolean(blockName))
        ?.map((props, i) => {
          const { blockName } = props;
          const Block = BlocksDirectory?.[blockName];

          console.log(blockName, 'blockNameblockName');

          if (Boolean(Block)) {
            return (
              <Fragment key={`${postId}_${i}`}>
                {/* {type === 'page' && i === 0 && <MenuSlider />} */}
                <AdsSlot place={`${postId}_post_content_${i}`} />
                {i === 4 && <AiCard />}

                {adsSlots &&
                  adsSlots[0]?.in_article_1?.client_id &&
                  i === 2 && <AdsenseSLots data={adsSlots[0]?.in_article_1} />}
                {article_summary && i == 0 && (
                  <ArticleSummary article_summary={article_summary} />
                )}
                {type === 'tech' && i === 2 && (
                  <DeviceSpecification acf={acf} />
                )}
                {adsSlots &&
                  adsSlots[0]?.in_article_2?.client_id &&
                  i === 3 && <AdsenseSLots data={adsSlots[0]?.in_article_2} />}
                {type !== 'page' && i === 3 && <VideoAdsSlot />}

                {bio_person_of_day &&
                blockName === 'acf/arageek-bio-person-of-day' ? (
                  <BioPersonOfDay data={bio_person_of_day} />
                ) : (
                  ''
                )}

                {/* {type !== 'page' && i === 4 && (
                  <JubnaAds id='c1a48d37ac2b067b1ab1b4cb12410157' />
                )} */}
                {i == blocks.length - 2 && (
                  <RelatedSlider
                    key={`carousel${i}`}
                    items={related_items}
                    type={type}
                  />
                )}
                {/* {i === 7 && <JubnaAds id='83e9b1ec6125e1a4c05bce71e24954eb' />} */}
                {i == blocks.length - 1 &&
                  adsSlots &&
                  adsSlots[0]?.sidebar?.client_id && (
                    <AdsenseSLots data={adsSlots[0]?.sidebar} />
                  )}
                <Block
                  {...props}
                  postId={postId}
                  blockIndex={i}
                  normalVertical={
                    i === 0 && blockName === 'acf/vertical-cards' ? true : false
                  }
                />
              </Fragment>
            );
          }
          if (Boolean(FallbackComponent)) {
            return <FallbackComponent key={i} {...props} />;
          }
          return null;
        })}
    </>
  );
};

export const GutenbergChildrenRenderer: React.FC = ({ blocks }) => {
  const FallbackComponent = BlocksDirectory?.['fallback'];
  return (
    <>
      {blocks
        ?.filter(({ blockName }) => blockName !== null)
        ?.map((props, i) => {
          const { blockName } = props;
          const Block = BlocksDirectory?.[blockName];

          if (Boolean(Block)) {
            return <Block key={i} {...props} />;
          }
          if (Boolean(FallbackComponent)) {
            return <FallbackComponent key={i} {...props} />;
          }
          return null;
        })}
    </>
  );
};
