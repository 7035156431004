import Script from 'next/script';
import React from 'react';

const AdsenseSLots = ({ data }) => {
  const { client_id, slot_id } = data;
  // console.log(client_id, slot_id, 'sssssssss');
  return (
    <div>
      <Script
        async
        strategy='afterInteractive'
        id={'adsense'}
        crossOrigin='anonymous'
        src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      />
      {/* <Box  as='ins'/> */}
      <ins
        className='adsbygoogle'
        style={{
          display: 'inline-block',
          width: '700px',
          height: '90px'
        }}
        //  style="display:inline-block;width:728px;height:90px"
        data-ad-client={client_id}
        // data-full-width-responsive='true'
        data-ad-slot={slot_id}
      ></ins>
      <Script
        id='nextjs-google-adsense'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
        (adsbygoogle = window.adsbygoogle || []).push({});
					`
        }}
      />
    </div>
  );
};

export default AdsenseSLots;
