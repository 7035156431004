import { Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const ArticleSummary = ({ article_summary }) => {
  const strippedHtml = article_summary?.replace(/<[^>]+>/g, '');
  const summaryArray = strippedHtml?.split(/[.]/);

  const array = summaryArray?.filter(item => item !== '\r\n');

  return (
    <Box p='1'>
      {/* {Array.isArray(summaryArray) && (
        <>
          <Heading
            as='span'
            fontSize={'h3'}

            //   color={'light.900'}
          >
            المقالة باختصـــــــــار 🚀
          </Heading>
        </>
      )} */}
      <VStack
        align={'stretch'}
        spacing={4}
        mt='0'
        borderBottom={'1px'}
        // borderTop={'1px'}
        borderColor={'gray.300'}
        // borderTop={'1px solid black'}
        pb='6'
        pt='6'
      >
        {Array.isArray(array) &&
          array?.map((item, index) => {
            return (
              item && (
                <HStack spacing={4} key={index} px={4} alignItems='baseline'>
                  <Box>
                    <Box
                      width={'7px'}
                      height={'9px'}
                      bg='light.900'
                      display={'block'}
                    />
                  </Box>

                  <Text fontSize={'h4'} fontWeight={'500'}>
                    {item?.toString()?.replace('&nbsp;', '')}.
                  </Text>
                </HStack>
              )
            );
          })}{' '}
      </VStack>
    </Box>
  );
};

export default ArticleSummary;
