import { BioCard } from 'components/shared';
import { memo } from 'react';
import { htmlParser } from 'utls';

const BioPersonOfDay = ({ data }) => {
  console.log(data, 'BioPersonOfDay');

  // const { title, link, excerpt, uri, thumb, small_thumb } = data;

  if (!data) return null;

  return (
    <BioCard
      title={data?.title}
      link={data?.link}
      image={{ url: data?.thumb ?? data?.small_thumb }}
    >
      {htmlParser(data?.excerpt)}
    </BioCard>
  );
};

export default memo(BioPersonOfDay);
