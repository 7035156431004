import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { Image } from 'components/shared';
import { NextChakraLink } from 'components/wrappers';
import { DollarCircle, ReceiptSearch } from 'iconsax-react';
import { htmlParser } from 'utls';
import useTranslation from 'hooks/useTranslation';

const AiCard = props => {
  const { T, locale } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { card_data } = props;

  if (!card_data) return null;

  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      align='center'
      as='article'
      position={'relative'}
      p='4'
      gap='2'
      py='8'
    >
      <Box
        position={'absolute'}
        bgColor='light.500'
        top={{ base: '6%', md: 0 }}
        bottom={0}
        left={{ base: 0, md: '6%' }}
        right={0}
        rounded={'md'}
      />

      <Flex
        flexDirection={{ base: 'column', md: 'column' }}
        align='center'
        gap='4'
      >
        <Box
          w={{ base: 300, sm: 350, md: 240 }}
          position={'relative'}
          rounded={'md'}
          boxShadow={'lg'}
          // order={{ base: 0, md: 1 }}
        >
          <Image
            image={{ url: card_data?.thumbnail }}
            ratio={16 / 9}
            rounded='sm'
            sizes={'(min-width: 62em) 20vw, 40vw'}
          />
        </Box>

        <Button
          bg='#FEE25D'
          borderRadius={'20px'}
          p='4 8'
          borderColor={'black'}
          borderWidth={2}
        >
          <NextChakraLink
            href={card_data?.website}
            color='black'
            target='_blank'
          >
            {T(`general.aiCard.visite_site.${locale}`)}
          </NextChakraLink>
        </Button>
      </Flex>

      <VStack
        align={'stretch'}
        flex={1}
        spacing={3}
        px={2}
        // py={8}
        zIndex={1}
        order={{ base: 1, md: 0 }}
      >
        <Heading as={'h3'} fontSize={'30px'}>
          {card_data?.title}
        </Heading>
        <Text color='dark.600' noOfLines={isOpen ? 'none' : 4}>
          {htmlParser(card_data?.description)}
        </Text>
        {!isOpen && card_data?.description.length > 200 && (
          <Text
            color='light.900'
            onClick={() => setIsOpen(true)}
            cursor={'pointer'}
          >
            {T(`general.show_more.${locale}`)}
          </Text>
        )}
        {card_data?.pricing && (
          <HStack align={'center'} spacing={1} fontSize={'18px'}>
            <DollarCircle color='#55595F' size='22' />
            <Text color='dark.600' fontWeight={'500'}>
              {T(`general.aiCard.price.${locale}`)}:
            </Text>
            <Text color='dark.600'>{card_data?.pricing}</Text>
          </HStack>
        )}

        {card_data?.genre && (
          <HStack align={'center'} spacing={1} fontSize={'18px'}>
            <ReceiptSearch color='#55595F' size='22' />
            <Text color='dark.600' fontWeight={'500'}>
              {T(`general.aiCard.genre.${locale}`)}:
            </Text>
            <Text color='dark.600'>{card_data?.genre}</Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  );
};

export default AiCard;
