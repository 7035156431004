import { VStack } from '@chakra-ui/react';
import { useRouteData } from 'hooks';
// import { SourcesSkeleton } from 'components/shared';
import dynamic from 'next/dynamic';
import { useRef } from 'react';
import { GutenbergRenderer } from './GutenbergRenderer';
import StructuredData from './StructuredData';
import MenuSlider from 'components/shared/MenuSlider';

const Sources = dynamic(() => import('components/shared/Sources'), {
  ssr: false
  // loading: SourcesSkeleton,
});

const HERO_blocks = [
  // 'acf/homepage-hero',
  'acf/arageek-bio-hero'
  // 'acf/vertical-cards',
  // 'acf/blog-hero',
  // 'acf/blog-choose-blogs'
];

export const Content: React.FC = ({ data, article_summary }) => {
  console.log(data, 'newData');

  const contentRef = useRef(null);

  const {
    gutenberg_blocks,
    id,
    type,
    acf,
    related_items,
    object_type,
    bio_person_of_day
  } = data;

  const blocks = gutenberg_blocks
    ?.slice(
      type === 'page' && gutenberg_blocks?.[0].blockName !== 'acf/blog-hero'
        ? 1
        : type === 'page' && gutenberg_blocks?.[0].blockName === 'acf/blog-hero'
        ? 2
        : 0
    )
    .filter(({ blockName }) => !HERO_blocks.includes(blockName));

  const MoviesArray = gutenberg_blocks?.filter(({ blockName }) => {
    return blockName === 'acf/arageek-movies-info';
  });

  return (
    <>
      <VStack
        ref={contentRef}
        sx={{ counterReset: 'css-counter 0' }}
        align={'stretch'}
        spacing={4}
      >
        <GutenbergRenderer
          {...{
            related_items,
            acf,
            type,
            id,
            blocks,
            object_type
          }}
          postId={id}
          article_summary={article_summary}
          bio_person_of_day={bio_person_of_day}
        />
      </VStack>
      <Sources contentRef={contentRef} />
      {Array.isArray(MoviesArray) && <StructuredData data={MoviesArray} />}
    </>
  );
};

export const HeroContent: React.FC = () => {
  // const { gutenberg_blocks, id, type } = useRouteData();

  const data = useRouteData();
  const blocks = data?.gutenberg_blocks?.filter(({ blockName }) =>
    HERO_blocks.includes(blockName)
  );

  if (
    !Array.isArray(data?.gutenberg_blocks) ||
    data?.gutenberg_blocks?.length === 0
  ) {
    return null;
  }

  return (
    <VStack align={'stretch'} spacing={4}>
      <GutenbergRenderer
        blocks={data?.type === 'page' ? [data?.gutenberg_blocks?.[0]] : blocks}
        // blocks={blocks}
        postId={data?.id}
        bio_person_of_day={data?.bio_person_of_day}
      />
      {data?.type === 'page' && <MenuSlider />}

      {data?.gutenberg_blocks?.[0]?.blockName === 'acf/blog-hero' && (
        <GutenbergRenderer
          blocks={
            data?.type === 'page' ? [data?.gutenberg_blocks?.[1]] : blocks
          }
          // blocks={blocks}
          postId={data?.id}
          bio_person_of_day={data?.bio_person_of_day}
        />
      )}
    </VStack>
  );
};
